import { requestApi } from "../../config/apiHandler";
import { signOut } from "../actions/auth";
export const fetchMetaDataStart = () => {
  return {
    type: "FETCH_METADATA_START",
  };
};

export const fetchMetaData = () => {
  return async (dispatch) => {
    dispatch(fetchMetaDataStart());

    const response = await requestApi("/meta", "GET", {});
    if (response.code == "success") {
      let parts_data = response.data.metadata.parts.map((m) => {
        let obj = {};
        obj.label = m.part;
        obj.value = m.parts;

        return obj;
      });
      return dispatch({
        type: "FETCH_METADATA_SUCCESS",
        payload: parts_data,
      });
    } else if (response.code == "authn_fail") {
      return dispatch(signOut());
    } else {
      return dispatch(fetchMetaDataFail());
    }
  };
};

export const fetchMetaDataFail = (msg) => {
  return {
    type: "FETCH_METADATA_FAIL",
    payload: msg,
  };
};

export const dismissError = () => {
  return {
    type: "DISMISS_ERROR_META",
  };
};
