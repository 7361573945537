import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import { analyticsReducer } from "./reducers/analytics";
import { authReducer } from "./reducers/auth";
import { meta } from "./reducers/meta";
import { overview } from "./reducers/overview";
import { model } from "./reducers/model";
import { features } from "./reducers/features";
import { leaderboard } from "./reducers/leaderboard";

const rootReducer = combineReducers({
  meta: meta,
  auth: authReducer,
  overview: overview,
  model: model,
  leaderboard: leaderboard,
  features: features,
});

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

export const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};
