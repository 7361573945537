import * as action_types from "../action_types/analytics";
const initialState = {
  loading: true,
  data: {},
  bar_data: {},
  count: 0,
  chart_loading: false,
  error: false,
  error_msg: null,
  part_loading: true,
  part_data: [],
};

export const features = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FEATURE_ANALYTICS_START":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_FEATURE_ANALYTICS_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case "FETCH_FEATURE_ANALYTICS_FAILURE":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loading: false,
      };
    case "FETCH_ANALYTICS_BY_PART_START":
      return {
        ...state,
        chart_loading: true,
      };
    case "FETCH_ANALYTICS_BY_PART":
      return {
        ...state,
        count: action.payload.count,
        bar_data: action.payload.bar_data,
        chart_loading: false,
      };
    case "DISMISS_ERROR_FEATURE_ANALYTICSS":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "FETCH_PART_DATA_START":
      return {
        ...state,
        part_loading: true,
      };
    case "FETCH_PART_DATA_SUCCESS":
      return {
        ...state,
        part_loading: false,
        part_data: action.payload,
      };
    case "FETCH_PART_DATA_FAILURE":
      return {
        ...state,
        part_loading: false,
        error: true,
        error_msg: action.payload,
      };

    default:
      return state;
  }
};
