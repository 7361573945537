const initialState = {
  loading: true,
  data: {},
  bar_data: {},
  error: false,
  error_msg: null,
};

export const model = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MODEL_ANALYTICS_START":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_MODEL_ANALYTICS_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case "FETCH_MODEL_ANALYTICS_FAILURE":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loading: false,
      };
    case "DISMISS_ERROR_MODEL_ANALYTICS":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    default:
      return state;
  }
};
