import * as action_types from "../action_types/analytics";
const initialState = {
  loading: true,
  data: {},
  bar_data: {},
  count: 0,
  chart_loading: false,
  error: false,
  error_msg: null,
  images: [],
  images_loading: true,
};

export const leaderboard = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USERS_ANALYTICS_START":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_USERS_ANALYTICS_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case "FETCH_USERS_ANALYTICS_FAILURE":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loading: false,
      };
    case "DISMISS_ERROR_USERS_ANALYTICS":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "FETCH_USER_IMAGES_START":
      return {
        ...state,
        images_loading: true,
      };
    case "FETCH_USERS_IMAGES_SUCCESS":
      return {
        ...state,
        images_loading: false,
        images: action.payload,
      };
    case "FETCH_USERS_IMAGES_FAILURE":
      return {
        ...state,
        images_loading: false,
        error: true,
        error_msg: action.payload,
      };

    default:
      return state;
  }
};
