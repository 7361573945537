import { requestApi } from "../../config/apiHandler";

export const restoreToken = (token) => {
  return {
    type: "RESTORE_TOKEN",
    token: token,
  };
};

export const signInSuccess = (token) => {
  return {
    type: "SIGN_IN_SUCCESS",
    token: token,
  };
};

export const signInStarted = () => {
  return {
    type: "SIGN_IN_STARTED",
  };
};

export const signIn = (email, password) => {
  return async (dispatch) => {
    dispatch(signInStarted());

    let token = null;
    let params = {};
    params.email = email;
    params.password = password;
    const response = await requestApi("/auths/login", "POST", params);
    if (response.code == "success") {
      try {
        token = response.data.session.token;
        await localStorage.setItem("x-auth", JSON.stringify(token));
        return dispatch(signInSuccess(token));
      } catch (e) {
        // saving error
        console.error(e);
      }
    } else {
      return dispatch(signInFailure(response.message));
    }
  };
};

export const signInFailure = (error) => {
  return {
    type: "SIGN_IN_FAILURE",
    payload: error,
  };
};

export const signOut = () => {
  return async (dispatch) => {
    const response = await requestApi("/auths/logout", "PUT", {});
    try {
      if (response.code == "success" || response.code == "authn_fail") {
        await localStorage.removeItem("x-auth");
        return dispatch({ type: "SIGN_OUT" });
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const signedOutSuccess = () => {
  return {
    type: "LOGGED_OUT",
  };
};

export const dismissError = () => {
  return {
    type: "DISMISS_ERROR",
  };
};
