import { lazy, Suspense } from "react";

import React from "react";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { Spinner } from "reactstrap";

const Login = lazy(() => import("./containers/Login"));
const Dashboard = lazy(() => import("./containers/Overview"));
const Models = lazy(() => import("./containers/Model"));
const Features = lazy(() => import("./containers/Features"));
const Agents = lazy(() => import("./containers/Agents"));
const Uploads = lazy(() => import("./containers/Uploads"));

const LazyLogin = (props) => {
  return (
    <Suspense
      fallback={
        <div style={{ backgroundColor: "#19191A" }}>
          <Spinner style={{ width: "3rem", height: "3rem" }} type="grow" />
        </div>
      }
    >
      <ErrorBoundary>
        <Login {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const LazyDashboard = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#19191A",
          }}
        >
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <Dashboard {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const LazyModels = (props) => {
  return (
    <Suspense
      fallback={
        <div className="content" style={{ backgroundColor: "#19191A" }}>
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <Models {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const LazyFeatures = (props) => {
  return (
    <Suspense
      fallback={
        <div className="content" style={{ backgroundColor: "#19191A" }}>
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <Features {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const LazyAgents = (props) => {
  return (
    <Suspense
      fallback={
        <div className="content" style={{ backgroundColor: "#19191A" }}>
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <Agents {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const LazyUploads = (props) => {
  return (
    <Suspense
      fallback={
        <div className="content" style={{ backgroundColor: "#19191A" }}>
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <Uploads {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LazyLogin,
    layout: "/auth",
  },
  {
    path: "/overview",
    name: "Overview",
    component: LazyDashboard,
    layout: "/admin",
    icon: "tim-icons icon-chart-pie-36",
  },
  {
    path: "/models",
    name: "Models",
    component: LazyModels,
    layout: "/admin",
    icon: "tim-icons icon-bullet-list-67",
  },

  {
    path: "/features",
    name: "Features",
    component: LazyFeatures,
    layout: "/admin",
    icon: "tim-icons icon-camera-18",
  },
  {
    path: "/agents",
    name: "Leaderboard",
    component: LazyAgents,
    layout: "/admin",
    icon: "tim-icons icon-trophy",
  },
  // {
  //   path: "/upload",
  //   name: "Upload Content",
  //   component: LazyUploads,
  //   layout: "/admin",
  //   icon: "tim-icons icon-cloud-upload-94",
  // },
];

export default routes;
