const axios = require("axios");

const isVision = process.env.REACT_APP_NAME === "tdc_vision" ? true : false;

export const profile = {
  app: isVision ? "vision" : "verbal",
  title: isVision ? "VISION ANALYTICS" : "TDC VERBAL ANALYTICS",
  contentTypeId: isVision ? "2" : "1",
  dataTypeName: isVision ? "Images" : "Questions",
};

const API_URL = process.env.REACT_APP_URL;

const headers = {
  "x-device-id": "analytics.device",
  "x-key": process.env.REACT_APP_API_KEY,
  "x-platform": 1,
  "x-origin": 3,
  "x-version": 1,
};

export const requestApi = async (resourcePath, method, params, token) => {
  const url = API_URL + resourcePath;
  headers["x-auth"] = JSON.parse(await localStorage.getItem("x-auth"));

  if (resourcePath == "/auths/login" || resourcePath == "/updates") {
    delete headers["x-auth"];
  }

  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    let response = await axios({
      url,
      method,
      data: params,
      headers,
      cancelToken: token,
    });
    return response.data;
  } else {
    let response = await axios({ url, method, headers, cancelToken: token });
    return response.data;
  }
};
