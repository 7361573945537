import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "../../layouts/Auth/Auth";
import AdminLayout from "../../layouts/Admin/Admin";
import { connect } from "react-redux";
import { restoreToken } from "../../store/actions/auth";
import { fetchMetaData } from "../../store/actions/meta";

const App = (props) => {
  useEffect(() => {
    props.fetchMetaData();
    const fetchToken = async () => {
      let userToken = await localStorage.getItem("x-auth");
      try {
        userToken = JSON.parse(await localStorage.getItem("x-auth"))
          ? JSON.parse(await localStorage.getItem("x-auth"))
          : null;
        props.restoreToken(userToken);
        if (userToken == null) {
          props.hist.push("/auth/login");
        }
      } catch (e) {
        // Restoring token failed
      }
    };
    fetchToken();
  }, [props.auth.userToken]);

  return (
    <Switch>
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />

      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Redirect from="/" to="/admin/overview" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  restoreToken: (token) => dispatch(restoreToken(token)),
  fetchMetaData: () => dispatch(fetchMetaData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
